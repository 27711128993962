import StylesRegisterServiceInterface from '~/src/pages/auth/register/styles/StylesRegisterServiceInterface'
import StylesRegisterServiceStyleOne from '~/src/pages/auth/register/styles/StylesRegisterServiceStyleOne'
import StylesRegisterServiceStyleThree
  from '~/src/pages/auth/register/styles/StylesRegisterServiceStyleThree'
import StylesRegisterServiceStyleTwo from '~/src/pages/auth/register/styles/StylesRegisterServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { LayoutState } from '~/src/stores/layout'

const getRegisterTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme, layout: LayoutState): StylesRegisterServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesRegisterServiceStyleTwo(muiTheme, undefined, layout)
    case TemplateEnum.STYLE_THREE:
      return new StylesRegisterServiceStyleThree(muiTheme, undefined, layout)
    default:
      return new StylesRegisterServiceStyleOne(muiTheme, undefined, layout)
  }
}

export default getRegisterTemplateService
