import { Box } from '@mui/material'
import RegisterForm from '~/src/components/auth/register/RegisterForm'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'

const RegisterContainer = () => {
  const { t } = useTranslations()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 6
      }}
    >
      <Box>
        <Title variant='h3'>
          {t('auth.signin')}
        </Title>
        <Title variant='h6'>
          {t('auth.createAnAccountToBeAbleToOrderOurDeliciousFood')}
        </Title>
      </Box>
      <RegisterForm />
    </Box>
  )
}

export default RegisterContainer
