import { RegisterResponse, Customer, HttpError } from '@eo-storefronts/eo-core'
import { useState } from 'react'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import routes from '~/src/router/enums/routes.enum'
import useSetAuthState from '~/src/hooks/auth/useSetAuthState'
import { register } from '~/src/services/AuthenticationService'
import RoutesEnum from '~/src/router/enums/routes.enum'
import useGetEmailReturnUrl from '~/src/hooks/router/useGetEmailReturnUrl'

interface ReturnsType {
  register(entity: Customer): Promise<boolean>,

  error: Record<string, string[]> | string | null,
  loading: boolean,
}

const useRegisterAsCustomer = (): ReturnsType => {
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const { generateReturnUrl } = useGetEmailReturnUrl()
  const [ error, setError ] = useState<string | Record<string, string[]> | null>(null)
  const [ loading, setLoading ] = useState<boolean>(false)
  const { setAuthState } = useSetAuthState()

  const registerAsCustomer = async (entity: Customer): Promise<boolean> => {
    setError(null)
    setLoading(true)

    try {
      const response: RegisterResponse | null = await register(
        entity,
        generateReturnUrl(RoutesEnum.ACTIVATE_ACCOUNT),
        false
      )

      if (!response) {
        return false
      }

      setAuthState({
        customer: {
          id: response.id,
          uid: response.uid,
          token: ''
        },
        loggedInAs: undefined
      })

      push(resolve(routes.ACCOUNT_ACTIVATION))
      setLoading(false)

      return true

    } catch (e) {
      const errors = (e as HttpError).errors

      if (errors) {
        setError(errors)
      }
    }

    setLoading(false)
    return false
  }

  return { register: registerAsCustomer, error, loading }
}

export default useRegisterAsCustomer
